import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import SectionHeader from '../components/sections/partials/SectionHeader';

class Home extends React.Component {

  state = {
    name: '',
    emailphone: '',
    message: '',
  }

  validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  validatePhone = (phone) => {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(String(phone).toLowerCase());
  }

  clickPrimary = () => {
    const { name, emailphone, message } = this.state;
    const errorMessage = 'Verify all fields are filled';
    const errorMessageEmailPhone = 'Please enter a valid email or phone.';
    // check if all forms are filled
    if (name.length === 0 || emailphone.length === 0 || message.length === 0) {
      alert(errorMessage);
      return false;
    }
    // check if emailphone is one or the other
    if (!this.validateEmail(emailphone) && !this.validatePhone(emailphone)) {
      alert(errorMessageEmailPhone);
      return false;
    }
    fetch('https://blue-penguin-d1105.firebaseio.com/contactus.json', {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(
      (response) => (response.json())
    ).then((response)=> {
      if (response.name && response.name.length > 0) {
        alert("Thank you for contacting us, we will contact you within the next 48 hours.");
        this.setState({
          name: '',
          emailphone: '',
          message: '',
        });
      } else if(response.error === 'Permission denied') {
        alert(errorMessage);
      } else {
        alert('Something went wrong. please refresh and try again');
      }
    })
  }

  onChange = (field, value) => {
    if(field === 'name') {
      this.setState({
        name: value
      });
    } else if(field === 'emailphone') {
      this.setState({
        emailphone: value
      });
    } else if(field === 'message') {
      this.setState({
        message: value
      });
    } else {
      this.state[field] = value;
    }
  }

  render() {
    const sectionHeader = {
      title: 'Contact Us',
      paragraph: ''
    };

    return (
      <>
        <Hero className="illustration-section-01" />
        <FeaturesTiles topDivider />
        {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
        <Testimonial topDivider />
        <SectionHeader data={sectionHeader} className="center-content"/>
        <div className="center-content">
          <div>
              <label htmlFor="name" className="contactus-lbl-name">Name</label>
              <input type="text" className="form-control" onChange={(event) => this.onChange("name", event.target.value)} value={this.state.name}/>
          </div>
          <div>
              <label htmlFor="exampleInputEmail1" className="contactus-lbl-email">Email address/Phone Number</label>
              <input type="emailphone" className="form-control" aria-describedby="emailHelp" onChange={(event) => this.onChange("emailphone", event.target.value)} value={this.state.emailphone}/>
          </div>
          <div>
              <label htmlFor="message" className="contactus-lbl-message">Message</label>
              <textarea className="form-control" rows="5" onChange={(event) => this.onChange("message", event.target.value)} value={this.state.message}></textarea>
          </div>
          <button className="btn btn-primary" onClick={this.clickPrimary}>Submit</button>
        </div>
        <div className="footer-padding"></div>
        {/* <Cta split /> */}
      </>
    );
  }
}

export default Home;